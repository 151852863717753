<template>
  <div>
    <b-overlay
      :show="reviewsStore.isPending"
      variant="secondary"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="lg"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="tableConfig.perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
            <!-- Search & Add review -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="tableConfig.searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  class="mr-1"
                >
                  <span class="text-nowrap">Search</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="reviewsStore.response"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="tableConfig.sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="tableConfig.isSortDirDesc"
        >
          <!-- Column: Created at -->
          <template #cell(createdAt)="data">
            <span>{{ data.item.createdAt | dateAndHoursUsLocale }}</span>
          </template>
          <!-- Column: rank -->
          <template #cell(rate)="data">
            <span class="d-block d-md-inline-block">
              <span class="d-block d-md-inline-block hearts-margin">
                <b-form-rating
                  id="rating"
                  v-model="data.item.rate"
                  icon-empty="heart"
                  icon-half="heart-half"
                  icon-full="heart-fill"
                  inline
                  readonly
                  precision="2"
                  no-border
                  variant="primary"
                  class="no-background font-medium-2 pl-0"
                />
              </span>
            </span>
          </template>
          <!-- Column: message -->
          <template #cell(message)="data">
            {{ data.item.message }}
          </template>
          <!-- Column: customer -->
          <template #cell(customer)="data">
            <router-link :to="{ name: customerEditLink, params: { id: data.item.customer.id } }">
              {{ data.item.customer.name }} {{ data.item.customer.lastName }}
            </router-link>
          </template>
          <!-- Column: appointment -->
          <template #cell(appointment)="data">
            <router-link :to="{ name: appointmentShowLink, params: { id: data.item.appointment.id } }">
              {{ renderServiceListName(data.item.appointment.services) }}
            </router-link>
          </template>
          <!-- Column: action -->
          <template #cell(action)="data">
            <b-button-group>
              <b-button
                v-if="[reviewStatuses.Pending, reviewStatuses.Rejected].includes(data.item.state)"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.top="'Approve this review'"
                variant="outline-secondary"
                class="mt-0"
                size="sm"
                @click="updateReviewState(data.item.id, reviewStatuses.Approved)"
              >
                <feather-icon icon="EditIcon" />
                <span>Approve</span>
              </b-button>

              <b-button
                v-if="[reviewStatuses.Pending, reviewStatuses.Approved].includes(data.item.state)"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.top="'Reject this review'"
                variant="outline-danger"
                class="mt-0"
                size="sm"
                @click="updateReviewState(data.item.id, reviewStatuses.Rejected)"
              >
                <feather-icon icon="TrashIcon" />
                <span>Reject</span>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ tableItems.from }} to {{ tableItems.to }} of {{ tableItems.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableConfig.currentPage"
                :total-rows="reviewsStore.totalItems"
                :per-page="tableConfig.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BPagination, BFormInput, BOverlay,
  BButton, BButtonGroup, BFormRating, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import vSelect from 'vue-select'
import StylistReviewStatusDictionary from '@/dictionaries/stylistReviewStatusDictionary'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { APPOINTMENT_SHOW, CUSTOMER_EDIT } from '@/router/routes/routes-names'
import Ripple from 'vue-ripple-directive'

export default {
  filters: {
    dateAndHoursUsLocale(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('MMMM D, yyyy h:mm a')
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormInput,
    BOverlay,
    BButton,
    BButtonGroup,
    BFormRating,
    vSelect,
  },
  data() {
    return {
      perPageOptions: [10, 25, 50, 100],
      appointmentShowLink: APPOINTMENT_SHOW,
      customerEditLink: CUSTOMER_EDIT,
      tableItems: {
        from: 0,
        to: 0,
        of: 0,
      },
      tableConfig: {
        currentPage: 1,
        perPage: 10,
        sortBy: 'state',
        isSortDirDesc: false,
        searchQuery: null,
      },
      tableColumns: [
        { key: 'createdAt', sortable: true, thStyle: { width: '15%' } },
        { key: 'rate', sortable: true, thStyle: { width: '5%' } },
        { key: 'message', sortable: false },
        { key: 'customer', sortable: false, thStyle: { width: '15%' } },
        { key: 'appointment', sortable: false },
        { key: 'action', sortable: false, thStyle: { width: '15%' } },
      ],
      changeStateActions: {},
      stylistReviewStatusDictionary: StylistReviewStatusDictionary,
      reviewStatuses: [],
    }
  },
  computed: {
    ...mapState('StylistReviewStoreModule', {
      reviewsStore: state => state.reviews,
    }),
  },
  watch: {
    tableConfig: {
      deep: true,
      handler() {
        this.getReviews()
      },
    },
    createReviewStore: {
      deep: true,
      handler(newReview) {
        if (newReview.response.length > 0) {
          this.getReviews()
        }
      },
    },
  },
  created() {
    this.getReviews()
    this.reviewStatuses = this.mapStatuses(StylistReviewStatusDictionary)
    this.changeStateActions = this.setChangeStateActionList()
  },
  methods: {
    ...mapActions('StylistReviewStoreModule', [
      'fetchReviews',
      'updateReview',
    ]),
    getReviews() {
      this.fetchReviews(this.getQueryParams())
    },
    getVariant(status) {
      switch (status) {
        case this.reviewStatuses.Pending:
          return 'light-info'
        case this.reviewStatuses.Rejected:
          return 'light-danger'
        case this.reviewStatuses.Approved:
          return 'light-success'
        default:
          return 'light-primary'
      }
    },
    setChangeStateActionList() {
      return {
        [this.reviewStatuses.Pending]: [
          { label: StylistReviewStatusDictionary[this.reviewStatuses.Approved], val: this.reviewStatuses.Approved },
          { label: StylistReviewStatusDictionary[this.reviewStatuses.Rejected], val: this.reviewStatuses.Rejected },
        ],
        [this.reviewStatuses.Approved]: [
          { label: StylistReviewStatusDictionary[this.reviewStatuses.Rejected], val: this.reviewStatuses.Rejected },
        ],
        [this.reviewStatuses.Rejected]: [
          { label: StylistReviewStatusDictionary[this.reviewStatuses.Approved], val: this.reviewStatuses.Approved },
        ],
      }
    },
    renderServiceListName(services) {
      const arr = []
      services.forEach(fields => {
        arr.push(fields.name)
      })
      return arr.join(', ')
    },
    mapSortBy(column) {
      switch (column) {
        case 'date':
          return 'createdAt'
        default:
          return column
      }
    },
    getQueryParams() {
      return {
        itemsPerPage: this.tableConfig.perPage,
        page: this.tableConfig.currentPage,
        search: this.tableConfig.searchQuery,
        [`order[${this.mapSortBy(this.tableConfig.sortBy)}]`]: this.tableConfig.isSortDirDesc ? 'desc' : 'asc',
      }
    },
    mapStatuses(stylistReviewStatusDictionary) {
      const reviewStatuses = []
      Object.keys(stylistReviewStatusDictionary).forEach(key => {
        const statusName = stylistReviewStatusDictionary[key]
        reviewStatuses[statusName] = +key
      })
      return reviewStatuses
    },
    async updateReviewState(id, state) {
      try {
        await this.updateReview({ id, state })
        this.getReviews()
      } catch (error) {
        this.error()
      }
    },
    error() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Something went wrong. Please try again.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
